import React from 'react';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Zoom from 'react-reveal/Zoom';
import Container from 'common/src/components/UI/ContainerTwo';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Link from 'common/src/components/Link';
import ourCustomer from 'common/src/assets/image/agencyDigital/ourCustomer.png';
import Section from './ContactUs.style';
import SectionHeading from '../SectionHeading';

const ContactUs = () => {
  return (
    <Section style={{ backgroundColor: '#ffffff' }} id="contact-us">
      <Container>
        <Zoom>
          <SectionHeading
            slogan="Contact Us"
            title="Connect with us: contact@meyvan.com or Contact:+91-9893240348"
          />
        </Zoom>

        {/*
          <Image
            src={ourCustomer}
            className="illustration"
            alt="Company who also worked us"
          />
          */}
      </Container>
    </Section>
  );
};

export default ContactUs;
