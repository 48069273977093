import React from 'react';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/feather/chevronRight';
import Zoom from 'react-reveal/Zoom';
import Container from 'common/src/components/UI/ContainerTwo';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Link from 'common/src/components/Link';
import ourCustomer from 'common/src/assets/image/agencyDigital/ourCustomer.png';
import Section from './service.style';
import SectionHeading from '../SectionHeading';

const OurCustomer = () => {
  return (
    <Section id="our-customer">
      <Container>
        <Zoom>
          <SectionHeading
            slogan="About Us"
            title="Founded in 2020 amid COVID-19 Pandemic by two childhood friends with a mission to build a platform that serves all software development needs. We will continue to work relentlessly to become the technological standard, providing big picture insights and solutions for companies of all sizes. Get in touch to learn more. Every email, web page, and social media post makes an impression on your customers. With our solutions you can be confident of it's impression."
          />
        </Zoom>
        {/*
          <Image
            src={ourCustomer}
            className="illustration"
            alt="Company who also worked us"
          />
          */}
      </Container>
    </Section>
  );
};

export default OurCustomer;
