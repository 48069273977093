import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';

import Container from 'common/src/components/UI/ContainerTwo';
import Image from 'common/src/components/Image';
import Link from 'common/src/components/Link';
import {
  Section,
  FooterTop,
  FooterWidget,
  FooterBottom,
  Copyright,
  FooterNav,
} from './footer.style';
import Logo from 'common/src/assets/image/agencyDigital/logo.png';

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      agencyDigitalJson {
        footer {
          id
          title
          list {
            id
            title
            link
          }
        }
        footerNav {
          id
          title
          link
        }
      }
    }
  `);
  const footer = data.agencyDigitalJson.footer;
  const footerNav = data.agencyDigitalJson.footerNav;
  return (
    <Section>
      <Container>
        {/*
        <FooterTop>
          {footer.map((item) => (
            <Fade key={item.id} up delay={100 * item.id}>
              <FooterWidget key={item.id}>
                <h4>{item.title}</h4>
                <ul>
                  {item.list.map((item) => (
                    <li className="widgetListItem" key={item.id}>
                      <Link href={item.link}>{item.title}</Link>
                    </li>
                  ))}
                </ul>
              </FooterWidget>
            </Fade>
          ))}
        </FooterTop>
        */}

        <FooterBottom>
          <Copyright>
            <Image src={Logo} alt="Meyvan India" />
          </Copyright>

          <FooterNav>
            <Copyright>
              <strong>
                Copyright &copy; {new Date().getFullYear()} by Meynigar Vanigam
                Private Limited
              </strong>
            </Copyright>
            <a href="https://www.linkedin.com/company/meyvan-meynigar-vanigam-pvt-ltd/?originalSubdomain=in">
              <img
                src="https://i.ibb.co/VQGyPTB/icons8-linkedin-96.png"
                alt="linkedin"
              />
            </a>
          </FooterNav>
        </FooterBottom>
      </Container>
    </Section>
  );
};

export default Footer;
